import SideBar from "./SideBar";
import styles from "../styles/Entries.module.css";
import TopBar from "./TopBar";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";


interface BookReviewProps {
    text: (string | JSX.Element)[];
    title: string;
    quotes: Array<string>;
    rating: string;
    date: string;
    author: string;
    reviewed: boolean;
}


const BookReview = ({ text, title, quotes, rating, date, author, reviewed }: BookReviewProps) => {

    const navigate = useNavigate();

    return (
        <div style={{ width: "60%", margin: "auto", marginTop: "5%" }}>
            <div className={styles.title}>
                <i>{title}</i> by {author}
                <hr />
            </div>
            <div className={styles.container}>
                <div className={styles.date}>
                    {date}
                </div>
                <div className={styles.link} onClick={() => { navigate("/reading") }}>
                    See all posts
                </div>
                {(!reviewed) ?
                    <div className={styles.body}>
                        <Typography variant="body1">
                            <p>Rating: {rating}/5</p>
                            <p>My review is on the way. For now, here are my favourite quotes from <i>{title}</i>:</p>
                            <div style={{ marginLeft: "1rem" }}>
                                {quotes.map(d => {
                                    return (
                                        <p>"{d}"</p>
                                    )
                                })}
                            </div>
                        </Typography>
                    </div>
                    :
                    <div className={styles.body}>
                        <Typography variant="body1">
                            <p>Rating: {rating}/5</p>
                            <div>
                                {text.map(d => {
                                    return (
                                        <p>{d}</p>
                                    )
                                })}
                            </div>
                            {(quotes.length !== 0) ?
                                <div>
                                    <p>Here are some of my favourite quotes from <i>{title}</i>:</p>
                                    <div style={{ marginLeft: "1rem" }}>
                                        {quotes.map(d => {
                                            let s = d;
                                            s.replace("&quot;", '\'');
                                            s.replace("&mdash;", "—-");
                                            return (
                                                <p>"{s}"</p>
                                            )
                                        })}
                                    </div>
                                </div>
                                :
                                <div></div>
                            }
                        </Typography>
                    </div>
                }
            </div>
        </div>

    )
}

export default BookReview;