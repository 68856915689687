import { useState } from "react";
import book_list from '../../util/load_books';
import styles from "../../styles/BlogsAndNotes.module.css";
import Title from "../../components/BlogsAndNotes/Title";


interface Book {
    date: Date;
    author: string;
    rating: number;
    title: string;
    tags: Array<String>;
    isbn: string;
}

interface ListProps {
    current: Array<String>;
}

const List = ({ current }: ListProps) => {
    const [books, setBooks] = useState<Array<Book>>(book_list);

    return (
        <div style={{ marginTop: "2rem", textAlign: "left" }}>
            <div className={styles.font}>
                The following are (probably) most of the books I've read in recent years.
                Some of these categories obviously don't make a lot of sense (economics and chess?).
                For some reason I decided that it was better to make ridiculous groupings
                than have more categories with only a few entries.
                <p>Currently reading: <i>{current[0]}</i> by {current[1]}</p>

                <div>
                    <div style={{ marginBottom: "1rem" }}>
                        <Title
                            date={""}
                            text={"Computer Science"}
                            title={""}
                            small={false}
                            pdf={null}
                        />
                    </div>
                    <div style={{ textAlign: "left" }}>
                        <ul>
                            {books.map(d => {
                                return (d.tags.includes("computer-science")) ?
                                    (<li key={d.isbn} style={{ marginBottom: "0.2rem" }}><i>{d.title}</i> by {d.author}</li>) : <div></div>
                            })}
                        </ul>
                    </div>
                </div>
                <div>
                    <div style={{ marginBottom: "1rem" }}>
                        <Title
                            date={""}
                            text={"Mathematics and Physics"}
                            title={""}
                            small={false}
                            pdf={null}
                        />
                    </div>
                    <div style={{ textAlign: "left" }}>
                        <ul>
                            {books.map(d => {
                                return (d.tags.includes("mathematics") ||
                                    d.tags.includes("physics")) ?
                                    (<li key={d.isbn} style={{ marginBottom: "0.2rem" }}><i>{d.title}</i> by {d.author}</li>) : <div></div>
                            })}
                        </ul>
                    </div>
                </div>
                <div>
                    <div style={{ marginBottom: "1rem" }}>
                        <Title
                            date={""}
                            text={"General Science"}
                            title={""}
                            small={false}
                            pdf={null}
                        />
                    </div>
                    <div style={{ textAlign: "left" }}>
                        <ul>
                            {books.map(d => {
                                return (d.tags.includes("science")) ?
                                    (<li key={d.isbn} style={{ marginBottom: "0.2rem" }}><i>{d.title}</i> by {d.author} </li>) : <div></div>
                            })}
                        </ul>

                    </div>
                </div>
                <div>
                    <div style={{ marginBottom: "1rem" }}>
                        <Title
                            date={""}
                            text={"Plays"}
                            title={""}
                            small={false}
                            pdf={null}
                        />
                    </div>
                    <div style={{ textAlign: "left" }}>
                        <ul>
                            {books.map(d => {
                                return (d.tags.includes("plays")) ?
                                    (<li key={d.isbn} style={{ marginBottom: "0.2rem" }}><i>{d.title}</i> by {d.author} </li>) : <div></div>
                            })}
                        </ul>

                    </div>
                </div>
                <div>
                    <div style={{ marginBottom: "1rem" }}>
                        <Title
                            date={""}
                            text={"American Literature"}
                            title={""}
                            small={false}
                            pdf={null}
                        />
                    </div>
                    <div style={{ textAlign: "left" }}>
                        <ul>
                            {books.map(d => {
                                return (d.tags.includes("american") && !d.tags.includes("plays")) ?
                                    (<li key={d.isbn} style={{ marginBottom: "0.2rem" }}><i>{d.title}</i> by {d.author} </li>) : <div></div>
                            })}
                        </ul>

                    </div>
                </div>
                <div>
                    <div style={{ marginBottom: "1rem" }}>
                        <Title
                            date={""}
                            text={"British Literature"}
                            title={""}
                            small={false}
                            pdf={null}
                        />
                    </div>
                    <div style={{ textAlign: "left" }}>
                        <ul>
                            {books.map(d => {
                                return (d.tags.includes("british") && !d.tags.includes("plays")) ?
                                    (<li key={d.isbn} style={{ marginBottom: "0.2rem" }}><i>{d.title}</i> by {d.author} </li>) : <div></div>
                            })}
                        </ul>

                    </div>
                </div>
                <div>
                    <div style={{ marginBottom: "1rem" }}>
                        <Title
                            date={""}
                            text={"Other Classics and Literature"}
                            title={""}
                            small={false}
                            pdf={null}
                        />
                    </div>
                    <div style={{ textAlign: "left" }}>
                        <ul>
                            {books.map(d => {
                                return ((d.tags.includes("literature") || d.tags.includes("classics")) && !(d.tags.includes("american") ||
                                    d.tags.includes("british")))
                                    ? (<li key={d.isbn} style={{ marginBottom: "0.2rem" }}><i>{d.title}</i> by {d.author}</li>) : <div></div>
                            })}
                        </ul>
                    </div>
                </div>
                <div>
                    <div style={{ marginBottom: "1rem" }}>
                        <Title
                            date={""}
                            text={"Science Fiction"}
                            title={""}
                            small={false}
                            pdf={null}
                        />
                    </div>
                    <div style={{ textAlign: "left" }}>
                        <ul>
                            {books.map(d => {
                                return (d.tags.includes("science-fiction")) ?
                                    (<li key={d.isbn} style={{ marginBottom: "0.2rem" }}><i>{d.title}</i> by {d.author}</li>) : <div></div>
                            })}
                        </ul>
                    </div>
                </div>
                <div>
                    <div style={{ marginBottom: "1rem" }}>
                        <Title
                            date={""}
                            text={"General Fiction"}
                            title={""}
                            small={false}
                            pdf={null}
                        />
                    </div>
                    <div style={{ textAlign: "left" }}>
                        <ul>
                            {books.map(d => {
                                return ((d.tags.includes("horror") ||
                                    d.tags.includes("historical-fiction") ||
                                    d.tags.includes("fantasy") ||
                                    d.tags.includes("fiction")) && !d.tags.includes("plays"))
                                    ? (<li key={d.isbn} style={{ marginBottom: "0.2rem" }}><i>{d.title}</i> by {d.author}</li>) : <div></div>
                            })}
                        </ul>
                    </div>
                </div>
                <div>
                    <div style={{ marginBottom: "1rem" }}>
                        <Title
                            date={""}
                            text={"Business and Biographies"}
                            title={""}
                            small={false}
                            pdf={null}
                        />
                    </div>
                    <div style={{ textAlign: "left" }}>
                        <ul>
                            {books.map(d => {
                                return (
                                    d.tags.includes("business") ||
                                    d.tags.includes("biography"))
                                    ? (<li key={d.isbn} style={{ marginBottom: "0.2rem" }}><i>{d.title}</i> by {d.author}</li>) : <div></div>
                            })}
                        </ul>
                    </div>
                </div>
                <div>
                    <div style={{ marginBottom: "1rem" }}>
                        <Title
                            date={""}
                            text={"Biology, Philosophy, Linguistics and Psychology"}
                            title={""}
                            small={false}
                            pdf={null}
                        />
                    </div>
                    <div style={{ textAlign: "left" }}>
                        <ul>
                            {books.map(d => {
                                return (
                                    d.tags.includes("philosophy") ||
                                    d.tags.includes("psychology") ||
                                    d.tags.includes("linguistics") ||
                                    d.tags.includes("biology"))
                                    ? (<li key={d.isbn} style={{ marginBottom: "0.2rem" }}><i>{d.title}</i> by {d.author}</li>) : <div></div>
                            })}
                        </ul>
                    </div>
                </div>
                <div>
                    <div style={{ marginBottom: "1rem" }}>
                        <Title
                            date={""}
                            text={"Economics, Art, History and Chess"}
                            title={""}
                            small={false}
                            pdf={null}
                        />
                    </div>
                    <div style={{ textAlign: "left", paddingBottom: "4rem" }}>
                        <ul>
                            {books.map(d => {
                                return (
                                    d.tags.includes("economics") ||
                                    d.tags.includes("chess") ||
                                    d.tags.includes("history"))
                                    ? (<li key={d.isbn} style={{ marginBottom: "0.2rem" }}><i>{d.title}</i> by {d.author}</li>) : <div></div>
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default List;