import BookReview from "../../components/BookReview";

const Alice = () => {
    return (
        <BookReview
            text={[
                "This is the kind of book you get when you just let your imagination run wild. There was really no plot and only a minor attempt at making the events cohesive. There are many talking animals with many of the likes and dislikes one might imagine animals to have if one were to foist this human notion upon them. For example, Alice realizes too late that it is uncouth to talk of her cat in front of the mice–they take great offence to that sort of thing.",

                "And there is a great focus on Alice’s size throughout the book. There are doors too small for her and tables too high for her; it takes some ingenuity to determine the proper positioning of things so that her size will be appropriate to her task. The mechanisms of her size-changing are drinks that say 'DRINK ME' and foods that say 'EAT ME.' Substances with this ability seem to be quite common even though every other organism seems to exist on a similar scale as far as size is concerned.",

                "And then Alice makes it to the Duchess’ house (was she really a Duchess?). The Duchess is nursing a crying child that turns out to be a pig. Everyone in Wonderland is mad. She is led to the Mad Hatter et al., who are stuck in an eternal tea party as punishment for the grievances they have inflicted upon Time. Everything in Wonderland is personified. Time is but another entity with thoughts and wishes and, consequently, the ability to be wronged. Being so wronged, the mechanism of his retaliation would surely center upon the fundamental quantity of which he is the sole arbiter.",

                <i>"Why is a raven like a writing desk?"</i>,

                "Alice’s original goal, remember, was to enter the tiny door at the bottom of the rabbit hole. She has been swept through various corners of Wonderland, meeting its inhabitants and observing their plights, but now Carroll decides to ground the story slightly by bringing Alice back to this door with the appropriate size modifier.",

                "And so she enters the garden and finds the Queen’s subjects–cards, like the Queen herself–painting white flowers red to appease her majesty, who much prefers the latter colour. The Queen issues many beheadings, none of which are actually carried out. She is the Evil Queen parodied. She is quite prone to yelling and issuing orders and comically oppressing her subjects.",
                "Her court is also a great entertaining parody. There is, of course, a careful order and manner of speaking that must be used in settings as formal as the court. And the court only deals with manners of great import (in this case the disappearance of the Queen’s tarts). Vacuous questions are issued and whimsical documents are presented as hard evidence. Alice is condemned but wakes up shortly thereafter. Wonderland is the imagination of a little girl."
            ]}
            title={"Alice's Adventures in Wonderland"}
            quotes={[]}
            date={"August 16, 2024"}
            rating={"4"}
            author="Lewis Carroll"
            reviewed={true}
        />
    )
}

export default Alice;
